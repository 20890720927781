import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { DataChange } from '../actions/enum/enum.actions';

@Injectable()
export class EnumResolver implements Resolve<boolean> {
  private cache$: Observable<any>;
  constructor(private http: HttpClient, private store: Store) {}

  private getUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase.length > 0 ? apiBase + '/api/app/enum' : 'api/app/enum';
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const url = this.getUrl();
    try {
      let req = this.http.get(url).pipe(
        map(r => {
          const action = new DataChange(r);
          this.store.dispatch(action);

          return true;
        }),
        catchError(err => {
          console.log('Api-app-enum catchError log', err);
          var errString = JSON.stringify(err);
          console.log('Api-app-enum catchError log json string', errString);
          return null;
        })
      );

      if (!this.cache$) {
        this.cache$ = req.pipe(shareReplay(1));
      }
    } catch (error) {
      console.log('Api-app-enum try-catch log', error);
      var errorString = JSON.stringify(error);
      console.log('Api-app-enum catchError log json string', errorString);
      throw error;
    }

    return this.cache$;
  }
}
