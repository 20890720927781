<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="clientId">
    <label for="clientId">{{ 'Settings::GenesysIntegrationClientId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="clientId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="clientSecret">
    <label for="clientSecret">
      {{ 'Settings::GenesysIntegrationClientSecret' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="clientSecret"
      [placeholder]="'Settings::MaskedAreaPlaceholder' | abpLocalization"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="region">
    <label for="region">{{ 'Settings::GenesysIntegrationRegion' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="region" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::GenesysIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="organizationId">
    <label for="organizationId">
      {{ 'Settings::GenesysIntegrationOrganizationId' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="organizationId"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="organizationName">
    <label for="organizationName">
      {{ 'Settings::GenesysIntegrationOrganizationName' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="organizationName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="groupId">
    <label for="groupId">{{ 'Settings::GenesysIntegrationGroupId' | abpLocalization }}</label>
    <input type="text" class="form-control form-control-sm" id="groupId" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="groupName">
    <label for="groupName">{{ 'Settings::GenesysIntegrationGroupName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="groupName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="chatIntegrationEnabled">
    <input
      type="checkbox"
      id="chatIntegrationEnabled"
      [(ngModel)]="chatIntegrationEnabledCb"
      formControlName="value"/>
      <label class="ps-1" for="chatIntegrationEnabled">
      {{ 'Settings::GenesysIntegrationChatIntegrationEnabled' | abpLocalization }}
    </label>
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
